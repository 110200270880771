import { mapGetters } from 'vuex';

export default {
    name: 'custom-breadcrumbs',
    data() {
        return {
        }
    },
    props: {
        list: {
            type: Array,
            default: null
        }
    },
    computed: {
        ...mapGetters({
            translations: 'setting/translations',
        })
    },
}
