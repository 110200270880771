import product from '@/components/product/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
import customBreadcrumbs from '@/components/custom-breadcrumbs/index.vue'
import _ from "lodash";
import categoryCard from "../category-all/components/category-card/index.vue";

import Vue from 'vue'
import VueHead from 'vue-head'

Vue.use(VueHead)

export default {
  name: "search",
  components: {
    product,
    customBreadcrumbs,
    categoryCard,
  },
  data(){
    return {
      listTrue: false,
      perPage: this.$route.query.per_page,
      params: {
        title: '',
        description: '',
        keywords: '',
        image: ''
      },
    }
  },
  head: {
    title: function () {
      return {
        inner: this.params.title !== '' ? this.params.title : '',
        separator: ' ',
      }
    },
    meta: function () {
      return [
        {n: 'keywords', content: this.params.keywords, id: 'keywords'},
        {n: 'robots', content: 'noindex, nofollow', id: 'robots'},
        {p: 'og:title', content: this.params.title, id: 'og:title'},
        {p: 'og:description', content: this.params.description, id: 'og:description'},
        {n: 'title', content: this.params.title , id: 'title'},
        {n: 'description', content: this.params.description , id: 'description'},
        {p: 'og:image', content: this.params.image, id: 'og:image'},
      ]
    },
    link: function () {
      let route = this.$route.path
      if (route.slice(1, 4) === 'ru/' || route.slice(1, 4) === 'en/'){
        route = route.slice(3)
      }
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' },
        {rel: 'alternate', hreflang: 'ru', href: window.location.origin + '/ru' + route, id: 'ru'},
        {rel: 'alternate', hreflang: 'en', href: window.location.origin+ '/en' + route, id: 'en'},
        {rel: 'alternate', hreflang: 'x-default', href: window.location.origin + route, id: 'x-default'},
      ]
    }

  },
  computed:{
    ...mapGetters({
      categoryBreadcrumbs:`category/categoryBreadcrumbs`,
      breadcrumbsList:`category/breadcrumbsList`,
      resultSearch: `search/resultPageSearch`,
      searchLoader: `search/pageSearchLoader`,
      noResultSearchPage: 'search/noResultSearchPage',
      isAuthenticated: `auth/isAuthenticated`,
      favourites: 'favorites/whichList',
      limit: `search/limit`,
      categoryName: `search/categoryName`,
    }),
    showBtn() {
      if(this.resultSearch.data.products.length < this.limit) {
        return false;
      } else {
        return true
      }
    },
    // list(){
    //   if (this.isAuthenticated) {
    //     if (!this.currentCategory.products) return []
    //     if (!this.favourites) return []
    //     return this.currentCategory.products.map(e => {
    //       const item = this.favoriteProducts.find(t => t.id === e.id)
    //       e.selected = !!item
    //       return e
    //     })
    //   } else {
    //     return this.currentCategory.products
    //   }
    // },
    favoriteProducts(){
      let arr = []
      if(this.isAuthenticated){
        for(let j in this.favourites){
          for(let i in this.favourites[j].products){
            arr.push(this.favourites[j].products[i])
          }
        }
        return arr
      }
    },
  },
  mounted() {
  
  },
 
  created() {
    if(this.$route.query.per_page !== this.limit) {
      this.resetLimit(this.$route.query.per_page)
      this.$router.replace({
        query: {
          search_text: this.$route.query.search_text,
          category_id: this.$route.query.category_id,
          page: 1,
          per_page: this.limit,
        }
      }).catch(() => {
        console.log()
      })
    }
    if(this.$route.query.search_text) {
      this.fetchSearchResult(this.$route.query)
    } else {
      this.$router.push({name: 'home'})
    }
    console.log('res', this.resultSearch.data)
  },
  watch: {
    '$route'(newVal) {
      if(newVal) {
        this.fetchSearchResult(this.$route.query)
      }
      if(!this.$route.query.search_text) {
        this.$router.push({name: 'home'})
      }
    }
  },
  methods: {
    ...mapActions({
      fetchSearchResult: `search/GET_SEARCH_PAGE_RESULT`
    }),
    ...mapMutations({
      changeActiveCategory:`category/ACTIVE_CATEGORY`,
      changeLimit: `search/SEARCH_SET_COUNT`,
      resetLimit: `search/RESET_LIMIT`
    }),
    setMeta() {
      this.params.title = this.currentCategory.meta_title !== '' ? this.currentCategory.meta_title : 'ICHIBAN STORE',
        this.params.description = this.currentCategory.meta_description !== '' ? this.currentCategory.meta_description : 'ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE',
        this.params.keywords = this.currentCategory.meta_keywords,
        this.params.image = this.currentCategory.image !== '' ? this.currentCategory.image : 'https://ichibanstore.jp/logo.png'
      this.$emit('updateHead')
    },
    showMore() {
      this.changeLimit();
      this.$router.replace({
        query: {
          search_text: this.$route.query.search_text,
          category_id: this.$route.query.category_id,
          page: 1,
          per_page: this.limit,
        }
      }).catch(() => {console.log()});
    }, 
  }
}